<template>
  <b-card-code>
    <div v-if="$can('create','Designation & Scale')" class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal">
          {{ $t('Add New') }}
        </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="designation_scales"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'designation_id'">
          {{ designationByID(props.row.designation_id) }}
        </span>
        <span v-else-if="props.column.field === 'scale_id'">
          {{ scaleByID(props.row.scale_id) }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="$can('status change','Designation & Scale')" :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id,props.row.originalIndex)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active?'Active':'Deactivated' }}
            </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button v-if="$can('update','Designation & Scale')" @click="selectedRow(props.row)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-login
                  variant="primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button v-if="$can('remove','Designation & Scale')" variant="danger" @click="remove(props.row.id,props.row.originalIndex)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal"
        hide-footer size="lg"
        :title="model_mode==='add'?$t('Add new'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="add_or_update_form">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Grade Name') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('grade name')"
                    rules="required"
                >
                  <b-form-input
                      v-model="name" autocomplete="off"
                      name="name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Grade name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Select designation') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('designation')"
                    rules="required"
                >
                  <select name="designation_id" v-model="designation_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(designation, key) in designations" :value="designation.id" :key="key">{{designation.name}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Select scale') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('scale')"
                    rules="required"
                >
                  <select name="scale_id" v-model="scale_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(scale, key) in scales" :value="scale.id" :key="key">{{scale.name}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Basic Salary (BS)') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('basic salary')"
                    rules="required"
                >
                  <b-form-input
                      v-model="basic_salary"
                      name="basic_salary"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('basic salary')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Cut BS per day absent') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('cut basic salary per day absent')"
                    rules="required"
                >
                  <b-form-input
                      v-model="cut_basic_salary_per_day_absent"
                      name="cut_basic_salary_per_day_absent"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('cut basic salary per day absent')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('House Rent (HR)') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('house rent')"
                    rules="required"
                >
                  <b-form-input
                      v-model="house_rent"
                      name="house_rent"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('house rent')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Cut HR per day absent') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('cut house rent per day absent')"
                    rules="required"
                >
                  <b-form-input
                      v-model="cut_house_rent_per_day_absent"
                      name="cut_house_rent_per_day_absent"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('cut house rent per day absent')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Medical Allowance (MA)') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('medical allowance')"
                    rules="required"
                >
                  <b-form-input
                      v-model="medical_allowance"
                      name="medical_allowance"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('medical allowance')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Cut MA per day absent') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('cut medical allowance per day absent')"
                    rules="required"
                >
                  <b-form-input
                      v-model="cut_medical_allowance_per_day_absent"
                      name="cut_medical_allowance_per_day_absent"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('cut medical allowance per day absent')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Transport Cost (TC)') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('transport cost')"
                    rules="required"
                >
                  <b-form-input
                      v-model="transport_cost"
                      name="transport_cost"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('transport cost')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Cut TC per day absent') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('cut transport cost per day absent')"
                    rules="required"
                >
                  <b-form-input
                      v-model="cut_transport_cost_per_day_absent"
                      name="cut_transport_cost_per_day_absent"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('cut transport cost per day absent')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Cut provident fund percentage') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('cut provident fund percentage')"
                    rules="required"
                >
                  <b-form-input
                      v-model="cut_provident_fund_percentage"  type="number"
                      name="cut_provident_fund_percentage"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('cut provident fund percentage')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Cut provident fund amount') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('cut provident fund amount')"
                    rules="required"
                >
                  <b-form-input
                      v-model="cut_provident_fund_amount" type="number"
                      name="cut_provident_fund_amount"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('cut provident fund amount')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button class="mt-2"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,
  BModal, BForm,BRow, BCol,BCardText,BFormCheckbox,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
/*import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";*/
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);

export default {
  name:'DesignationScale',
  components: {
    BCardCode,//AgGridVue,
    VueGoodTable,
    BBadge,BCardText,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,BFormCheckbox,
    BButton,
    BModal,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      designation_id:null,
      scale_id:null,
      basic_salary:0,
      cut_basic_salary_per_day_absent:0,
      house_rent:0,
      cut_house_rent_per_day_absent:0,
      medical_allowance:0,
      cut_medical_allowance_per_day_absent:0,
      transport_cost:0,
      cut_transport_cost_per_day_absent:0,
      cut_provident_fund_percentage:0,
      cut_provident_fund_amount:0,
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Grade',
          field: 'name',
        },
        {
          label: 'Designation',
          field: 'designation_id',
        },
        {
          label: 'Scale',
          field: 'scale_id',
        },
        {
          label: 'Basic Salary',
          field: 'basic_salary',
        },
        {
          label: 'Cut Basic Salary per day absent',
          field: 'cut_basic_salary_per_day_absent',
        },
        {
          label: 'House rent',
          field: 'house_rent',
        },
        {
          label: 'Cut House rent per day absent',
          field: 'cut_house_rent_per_day_absent',
        },
        {
          label: 'Medical Allowance',
          field: 'medical_allowance',
        },
        {
          label: 'Cut Medical Allowance per day absent',
          field: 'cut_medical_allowance_per_day_absent',
        },
        {
          label: 'Transport Cost',
          field: 'transport_cost',
        },
        {
          label: 'Cut Transport cost per day absent',
          field: 'cut_transport_cost_per_day_absent',
        },
        {
          label: 'Cut Provident fund percentage',
          field: 'cut_provident_fund_percentage',
        },
        {
          label: 'Cut Provident fund amount',
          field: 'cut_provident_fund_amount',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status: [{
        1: 'active',
        2: 'deactivate',
      },
        {
          1: 'light-primary',
          2: 'light-danger',
        }],
      columns10: [
        {
          label: 'Grade',
          field: 'Grade',
        },
        {
          label: 'Designation',
          field: 'Designation',
        },
        {
          label: 'Scale',
          field: 'Scale',
        },
        {
          label: 'Basic Salary',
          field: 'BasicSalary',
        },
        {
          label: 'Cut Basic Salary Per Day Absent',
          field: 'CutBasicSalaryPerDayAbsent',
        },
        {
          label: 'House Rent',
          field: 'HouseRent',
        },
        {
          label: 'Cut House Rent Per Day Absent',
          field: 'CutHouseRentPerDayAbsent',
        },
        {
          label: 'Medical Allowance',
          field: 'MedicalAllowance',
        },
        {
          label: 'Cut Medical Allowance Per Day Absent',
          field: 'CutMedicalAllowancePerDayAbsent',
        },
        {
          label: 'Transport Cost',
          field: 'TransportCost',
        },
        {
          label: 'Cut Transport Cost Per Day Absent',
          field: 'CutTransportCostPerDayAbsent',
        },
        {
          label: 'Cut Provident fund Percentage',
          field: 'CutProvidentFundPercentage',
        },
        {
          label: 'Cut Provident Fund Amount',
          field: 'CutProvidentFundAmount',
        },
        {
          label: 'Status',
          field: 'Status',
        },
      ],
    }
  },
  methods:{
    selectedRow(row){
      this.selected_row=row;
      this.name=row.name;
      this.designation_id=row.designation_id;
      this.scale_id=row.scale_id;
      this.basic_salary=row.basic_salary;
      this.cut_basic_salary_per_day_absent=row.cut_basic_salary_per_day_absent;
      this.house_rent=row.house_rent;
      this.cut_house_rent_per_day_absent=row.cut_house_rent_per_day_absent;
      this.medical_allowance=row.medical_allowance;
      this.cut_medical_allowance_per_day_absent=row.cut_medical_allowance_per_day_absent;
      this.transport_cost=row.transport_cost;
      this.cut_transport_cost_per_day_absent=row.cut_transport_cost_per_day_absent;
      this.cut_provident_fund_percentage=row.cut_provident_fund_percentage;
      this.cut_provident_fund_amount=row.cut_provident_fund_amount;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_or_update_form);
          if (this.model_mode ==='add'){
            apiCall.post('/designation/scale/store',data).then((response)=>{
              if (response.data.status==='success'){
                this.hideModal();
                //this.$store.commit('ADD_NEW_DESIGNATION_SCALE',response.data.data);
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_DESIGNATION_SCALE');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/designation/scale/update${this.selected_row.id}`,data).then((response)=>{
              if (response.data.status==='success'){
                this.hideModal();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_DESIGNATION_SCALE');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    statusChange(id,index){
      apiCall.put(`/designation/scale/status/change${id}`).then((response)=>{
        this.$store.commit('STATUS_CHANGE_DESIGNATION_SCALE',index);
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_DESIGNATION_SCALE');
      });
    },
    remove(id,index){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.delete(`/designation/scale/remove${id}`).then((response)=>{
              this.$store.commit('REMOVE_DESIGNATION_SCALE',index);
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.name='';
      this.designation_id=null;
      this.scale_id=null;
      this.basic_salary=0;
      this.cut_basic_salary_per_day_absent=0;
      this.house_rent=0;
      this.cut_house_rent_per_day_absent=0;
      this.medical_allowance=0;
      this.cut_medical_allowance_per_day_absent=0;
      this.transport_cost=0;
      this.cut_transport_cost_per_day_absent=0;
      this.cut_provident_fund_percentage=0;
      this.cut_provident_fund_amount=0;
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    pdfGenerate() {
      let title = "Designation & Scales";
      let clm = ['Grade','Designation','Scale','BasicSalary','CutBasicSalaryPerDayAbsent','HouseRent','CutHouseRentPerDayAbsent','MedicalAllowance','CutMedicalAllowancePerDayAbsent','TransportCost','CutTransportCostPerDayAbsent','CutProvidentFundPercentage','CutProvidentFundAmount', 'Status' ];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'designation_scale.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['designation_scales','designations','scales','designationByID','scaleByID']),
     getData() {
      return this.designation_scales.map(item => ({
        Grade: item.name,
        Designation: item.designation_id ? this.designationByID(item.designation_id) : '',
        Scale: item.scale_id ? this.scaleByID(item.scale_id) : '',
        BasicSalary: item.basic_salary ? item.basic_salary : 0,
        CutBasicSalaryPerDayAbsent: item.cut_basic_salary_per_day_absent ? item.cut_basic_salary_per_day_absent : 0,
        HouseRent: item.house_rent ? item.house_rent : 0,
        CutHouseRentPerDayAbsent: item.cut_house_rent_per_day_absent ? item.cut_house_rent_per_day_absent : 0,
        MedicalAllowance: item.medical_allowance ?item.medical_allowance : 0,
        CutMedicalAllowancePerDayAbsent: item.cut_medical_allowance_per_day_absent ? item.cut_medical_allowance_per_day_absent: 0,
        TransportCost: item.transport_cost ? item.transport_cost : 0,
        CutTransportCostPerDayAbsent: item.cut_transport_cost_per_day_absent? item.cut_transport_cost_per_day_absent : 0,
        CutProvidentFundPercentage: item.cut_provident_fund_percentage ? item.cut_provident_fund_percentage : 0,
        CutProvidentFundAmount: item.cut_provident_fund_amount ? item.cut_provident_fund_amount : 0,
        Status: item.is_active ? 'Active' : 'Inactive'
      }));
    }
  },
  created() {
    if(this.designation_scales.length<1) this.$store.dispatch('GET_ALL_DESIGNATION_SCALE');
    if(this.designations.length<1) this.$store.dispatch('GET_ALL_DESIGNATION');
    if(this.scales.length<1) this.$store.dispatch('GET_ALL_SCALE');
  },
  watch:{
    cut_provident_fund_percentage(){
      this.cut_provident_fund_amount = this.basic_salary*(this.cut_provident_fund_percentage/100);
    },
    cut_provident_fund_amount(){
      this.cut_provident_fund_percentage =  (this.cut_provident_fund_amount*100)/this.basic_salary;
    },
    basic_salary(){
      this.cut_provident_fund_amount = this.basic_salary*(this.cut_provident_fund_percentage/100);
      this.cut_provident_fund_percentage =  (this.cut_provident_fund_amount*100)/this.basic_salary;
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>